import {
    Button,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useGetCompanyList } from 'api/hooks';
import {
    AssessmentFilters,
    AssessmentListResponse,
    AssessmentStatus,
} from 'api/hooks/assessment/assessmentTypes';
import { useGetUsersForSelects } from 'api/hooks/user/useGetUsersForSelects';
import { RoleType } from 'api/hooks/user/userTypes';
import { CustomAutoCompleteField } from 'components/shared/form/custom-autocomplete-field';
import { CustomMultipleSelectField } from 'components/shared/form/custom-multiple-select-field';
import { FC, useState } from 'react';
import { EnumToDropdownOptions, multipleSelectOptionsToString } from 'utils';
import { dateToAPIDate } from 'utils/common';

type Props = {
    filters: AssessmentFilters;
    onFilterChange: (updatedFilters: AssessmentFilters) => void;
    onSearch: () => void;
    onClear: () => void;
    assessments: AssessmentListResponse;
};

export const AssessmentFilterSection: FC<Props> = ({
    filters,
    onFilterChange,
    onSearch,
    onClear,
    assessments,
}) => {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const { data: companyData } = useGetCompanyList({});
    const companyNames = companyData?.map((item) => ({
        value: item?.name || '',
        label: item?.name || '',
    }));
    const { data: analysts, isLoading: analystsLoading } =
        useGetUsersForSelects({
            filters: { roles: [RoleType.ANALYST] },
        });
    const { data: assurers, isLoading: assurersLoading } =
        useGetUsersForSelects({
            filters: { roles: [RoleType.ASSURER] },
        });

    const toggleFormVisibility = () => {
        setIsFormVisible(!isFormVisible);
    };

    const handleInputChange = (
        propertyName: keyof AssessmentFilters,
        value: string | string[] | null
    ) => {
        const updatedFilters = { ...filters, [propertyName]: value };
        onFilterChange(updatedFilters);
    };

    const handleMultipleSelect = (event: unknown | SelectChangeEvent) => {
        const { name, value } = (event as SelectChangeEvent).target;
        const newValue = Array.isArray(value)
            ? value.length
                ? multipleSelectOptionsToString(value)
                : null
            : value;

        // formik.setFieldValue(name, newValue);
        const updatedFilters = { ...filters, [name]: newValue?.split(';') };
        onFilterChange(updatedFilters);
    };

    const handleMultipleDelete = (
        value: string,
        name: keyof AssessmentFilters
    ) => {
        const newArray = filters?.status?.filter((current) => {
            return current !== value;
        });
        const updatedFilters = { ...filters, [name]: newArray };
        onFilterChange(updatedFilters);
    };

    const assessmentStatusesOptions = EnumToDropdownOptions(AssessmentStatus);

    const analystOptions = analysts?.map((user) => ({
        value: user._id,
        label: `${user.name} ${user.last_name}`,
    }));
    const assurerOptions = assurers?.map((user) => ({
        value: user._id,
        label: `${user.name} ${user.last_name}`,
    }));

    if (analystsLoading || assurersLoading) {
        return null;
    }

    return (
        <Container>
            <Button
                variant='contained'
                color='primary'
                onClick={toggleFormVisibility}
            >
                {isFormVisible ? 'Hide Filters' : 'Show Filters'}
            </Button>
            {isFormVisible && (
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={3}>
                        {/* <TextField
                            label='Company is Client'
                            fullWidth
                            value={filters.company_is_client || ''}
                            onChange={(e) =>
                                handleInputChange(
                                    'company_is_client',
                                    e.target.value
                                )
                            }
                        /> */}
                        <FormControl fullWidth>
                            <InputLabel id='company_is_client'>
                                Company is Client
                            </InputLabel>
                            <Select
                                id='company_is_client'
                                label='Company is Client'
                                value={filters.company_is_client}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    handleInputChange(
                                        'company_is_client',
                                        value ? value.toString() : null
                                    );
                                }}
                            >
                                <MenuItem value=''>
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={'true'}>True</MenuItem>
                                <MenuItem value={'false'}>False</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label='Company ISIN (Separate with ;)'
                            fullWidth
                            value={(filters.company_isin || []).join(';')}
                            onChange={(e) =>
                                handleInputChange(
                                    'company_isin',
                                    e.target.value.split(';')
                                )
                            }
                        />
                    </Grid>{' '}
                    <Grid item xs={3}>
                        <CustomAutoCompleteField
                            options={companyNames || []}
                            onChange={(value) => {
                                handleInputChange(
                                    'company_name',
                                    value
                                        ? value.split(';').map((el: string) => {
                                              return JSON.parse(el).value;
                                          })
                                        : []
                                );
                            }}
                            value={(filters.company_name || [])
                                .map((el) => {
                                    return JSON.stringify({
                                        value: el,
                                        label: el,
                                    });
                                })
                                .join(';')}
                            label={'Company Name'}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CustomAutoCompleteField
                            options={assurerOptions}
                            onChange={(value) => {
                                // handleAutoCompleteSelect('analyst_id', value);
                                handleInputChange(
                                    'assurer_id',
                                    value
                                        ? value.split(';').map((el: string) => {
                                              return JSON.parse(el).value;
                                          })
                                        : []
                                );
                            }}
                            value={(filters.assurer_id || [])
                                .map((el) => {
                                    const user = assurers.find(
                                        (user) => user._id === el
                                    );
                                    return JSON.stringify({
                                        value: el,
                                        label: `${user?.name} ${user?.last_name}`,
                                    });
                                })
                                .join(';')}
                            label={'Assurer'}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CustomAutoCompleteField
                            options={analystOptions}
                            onChange={(value) => {
                                // handleAutoCompleteSelect('analyst_id', value);
                                handleInputChange(
                                    'analyst_id',
                                    value
                                        ? value.split(';').map((el: string) => {
                                              return JSON.parse(el).value;
                                          })
                                        : []
                                );
                            }}
                            value={(filters.analyst_id || [])
                                .map((el) => {
                                    const user = analysts.find(
                                        (user) => user._id === el
                                    );
                                    return JSON.stringify({
                                        value: el,
                                        label: `${user?.name} ${user?.last_name}`,
                                    });
                                })
                                .join(';')}
                            label={'Analyst'}
                        />
                        {/* <TextField
                            label='Analyst ID (Separate with ;)'
                            fullWidth
                            value={(filters.analyst_id || []).join(';')}
                            onChange={(e) =>
                                handleInputChange(
                                    'analyst_id',
                                    e.target.value.split(';')
                                )
                            }
                        /> */}
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label='Fiscal Year (Separate with ;)'
                            fullWidth
                            value={(filters.fiscal_year || []).join(';')}
                            onChange={(e) =>
                                handleInputChange(
                                    'fiscal_year',
                                    e.target.value.split(';')
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DesktopDatePicker
                            inputFormat='DD/MM/YYYY'
                            value={filters.assessment_deadline_from || null}
                            onChange={(newValue) => {
                                if (newValue)
                                    handleInputChange(
                                        'assessment_deadline_from',
                                        dateToAPIDate(newValue)
                                    );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Assessment Deadline From'
                                    variant='outlined'
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DesktopDatePicker
                            inputFormat='DD/MM/YYYY'
                            value={filters.assessment_deadline_to || null}
                            onChange={(newValue) => {
                                if (newValue)
                                    handleInputChange(
                                        'assessment_deadline_to',
                                        dateToAPIDate(newValue)
                                    );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Assessment Deadline To'
                                    variant='outlined'
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {/* <FormControl fullWidth>
                            <InputLabel id='status'>Status</InputLabel>
                            <Select
                                id='status'
                                label='Status'
                                value={filters.status || ''}
                                onChange={(e) =>
                                    handleInputChange('status', e.target.value)
                                }
                            >
                                <MenuItem value=''>
                                    <em>None</em>
                                </MenuItem>
                                {assessmentStatusesOptions &&
                                    assessmentStatusesOptions.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label || option.value}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl> */}
                        <FormControl fullWidth>
                            {/* <InputLabel id='status'>Qa Status</InputLabel> */}
                            <CustomMultipleSelectField
                                options={assessmentStatusesOptions}
                                onChange={handleMultipleSelect}
                                // {...generateMultipleSelectFieldProps<FormikTypes>(
                                //     'dataFormat',
                                //     'Data Format',
                                //     formName,
                                //     formik
                                // )}
                                value={filters?.status?.join(';')}
                                name='status'
                                formName='AssessmentForm'
                                label='Status'
                                errorText={''}
                                handleDelete={(value) =>
                                    handleMultipleDelete(value, 'status')
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <DesktopDatePicker
                            inputFormat='DD/MM/YYYY'
                            value={filters.assurance_deadline_from || null}
                            onChange={(newValue) => {
                                if (newValue)
                                    handleInputChange(
                                        'assurance_deadline_from',
                                        dateToAPIDate(newValue)
                                    );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Assurance Deadline From'
                                    variant='outlined'
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DesktopDatePicker
                            inputFormat='DD/MM/YYYY'
                            value={filters.assurance_deadline_to || null}
                            onChange={(newValue) => {
                                if (newValue)
                                    handleInputChange(
                                        'assurance_deadline_to',
                                        dateToAPIDate(newValue)
                                    );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Assurance Deadline To'
                                    variant='outlined'
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={1.5}>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={onSearch}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={1.5}>
                        <Button
                            variant='outlined'
                            color='secondary'
                            fullWidth
                            onClick={onClear}
                        >
                            Clear
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Container>
    );
};
